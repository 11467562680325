<template>
  <div class="container-fluid no-gutters pl-0">
    <sp-loader v-if="!userLoaded" />
    <div v-if="userLoaded" class="row no-gutters">
      <div class="col-4 col-md-3 col-lg-2 border-right pt-4" style="height: 85vh">
        <b-list-group flush>
          <b-list-group-item :href="SPENV.APP_URL + '/lmsapp/dashboard'" class="border-0">
            Go to the app
          </b-list-group-item>
          <b-list-group-item href="#" active class="border-0">
            Health check
          </b-list-group-item>
          <b-list-group-item :href="SPENV.ADMIN_PANEL_URL + '/backend/new'" class="border-0">
            SP admin panel
          </b-list-group-item>
          <b-list-group-item :href="SPENV.ACCOUNTS_API_URL + '/logout'" class="border-0">
            Logout
          </b-list-group-item>
        </b-list-group>
      </div>
      <div class="col-8 col-md-9 col-lg-10 mt-4">
        <p class="pl-4">
          Welcome back {{ user.first_name || user.email }}!
        </p>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import SpLoader from '@/components/SPLoader';
import redirectMixin from '@/utils/mixins/Redirect';

export default {
  name: 'Admin',
  components: {
    'sp-loader': SpLoader,
  },
  mixins: [redirectMixin],
  data() {
    return {
      userLoaded: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    // Wait for user to resolve to see if user is logged in jcervantes 08-01-2018
    next(vm => {
      if (!vm.$store.getters['user/getUser']) {
        vm.$store
          .dispatch('header/loadHeaderData').then(
            () => {
              if (!vm.$store.getters['user/isEmployee']) {
                // if user is not an employee redirect them back to lmsapp dashboard jcervantes 08-01-2018
                vm.redirectUrl(process.env.SPENV.APP_URL + '/lmsapp/dashboard');
              } else {
                vm.userLoaded = true;
              }
            })
          .catch(error => {
            // kick to error state
            console.log(error);
          });
      }
    });
  },
};
</script>
